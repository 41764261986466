import store from "@/store/index";
import router from '@/router';
import makeRequest from "@/requests/makeRequest";
import {
    ADD_LAYOUT,
    UPDATE_LAYOUT,
    DELETE_LAYOUT,
    CLONE_LAYOUT,
    UPDATE_LAYOUT_RECORDS,
    DELETE_LAYOUT_RECORDS
} from "@/queries";

export const addLayout = (payload, editId) => {
    let variables = {
        title: payload.title,
        color: payload.color,
        headings: payload.headings,
        panels: payload.panels,
        colsPerPanel: payload.colsPerPanel,
        recordsPerColumn: payload.recordsPerColumn,
        currentDate: new Date().toISOString(),
    }

    if (payload.titleStrip) {
        variables.primaryColor = payload.titleStrip.primaryColor
        variables.secondaryColor = payload.titleStrip.secondaryColor
        variables.tertiaryColor = payload.titleStrip.tertiaryColor
        variables.design = payload.titleStrip.design
    }

    let query = UPDATE_LAYOUT;
    if (editId) {
        variables.id = editId;
    } else {
        query = ADD_LAYOUT;
        variables.userId = store.getters.getUser.id
    }

    return makeRequest(query, variables).then((data) => {
        store.dispatch("getLayouts");
        router.push({ name: 'designerlayout', params: { id: data.data.data.insertOneLayout._id } });
    });
}

export const deleteLayout = (id) => {
    makeRequest(DELETE_LAYOUT_RECORDS, { id })
    makeRequest(DELETE_LAYOUT, {
        id,
    }).then(() => {
        store.dispatch("getLayouts", true);
    });
}

export const cloneLayout = (id) =>
    makeRequest(CLONE_LAYOUT,
        { input: id },
    ).then(() => {
        store.dispatch("getLayouts", true);
    });

export const saveLayoutRecords = (id, updatedLayout) => {
    const records = [];

    updatedLayout.flat().forEach((item, position) => {
        if (item && item._id) {
            records.push({
                owner: { link: store.getters.getUser.id },
                layout: { link: id },
                record: { link: item._id },
                position,
            });
        }
    });

    const request = {
        id,
        records,
        currentDate: new Date().toISOString()
    };

    return makeRequest(DELETE_LAYOUT_RECORDS, request).then(() => {
        if (records.length === 0) return Promise.resolve();
        return makeRequest(UPDATE_LAYOUT_RECORDS, request)
    });

};
