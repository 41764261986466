
import makeRequest from "@/requests/makeRequest";
import store from "@/store/index";
export * from "@/requests/layout.js";
export * from "@/requests/print.js";
export * from "@/requests/records.js";
import {
  UPDATE_PROFILE,
} from "@/queries";

export const updateProfile = ({ discogsUsername, discogsToken }) => {
  const request = {
    userId: store.getters.getUser.id,
    discogsUsername,
    discogsToken,
  };
  return makeRequest(UPDATE_PROFILE, request).then(() => {
    // store.dispatch("updateUserMeta", request);
  });
};
