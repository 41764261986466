import store from "@/store/index";

import makeRequest from "@/requests/makeRequest";
import {
  RECORD_SEARCH,
  ADD_RECORD,
  UPDATE_RECORD,
  DELETE_RECORD,
  GET_DISCOGS_DATA,
} from "@/queries";

export const deleteRecord = (id) =>
  makeRequest(DELETE_RECORD, {
    id,
  }).then(() => {
    store.dispatch("getRecordCount", true);
  });


export const addRecord = (payload) => {
  const addRecordRequest = makeRequest(ADD_RECORD, {
    userId: store.getters.getUser.id,
    artist: payload.artist,
    speed: payload.speed,
    year: payload.year,
    discogsId: payload.discogsId,
    acquired: payload.acquired.toISOString(),
    currentDate: new Date().toISOString(),
    sideATitle: payload.side.a.title,
    sideBTitle: payload.side.b.title,
  });

  const getDiscogsRequest = makeRequest(GET_DISCOGS_DATA, { id: payload.discogsId });

  // Use Promise.all to wait for both requests to complete
  return Promise.all([addRecordRequest, getDiscogsRequest]).then((results) => {
    store.dispatch("getRecordCount", true);
    return results
  });
};

export const updateRecord = (payload) =>
  makeRequest(UPDATE_RECORD, {
    id: payload._id,
    artist: payload.artist,
    year: payload.year,
    speed: payload.speed,
    acquired: new Date(payload.acquired).toISOString(),
    discogsId: payload.discogsId,
    currentDate: new Date().toISOString(),
    sideATitle: payload.side.a.title,
    sideBTitle: payload.side.b.title,
  });

export const searchRecords = (ctx) => {
  let limit = ctx.perPage;
  let offset = ctx.perPage * (ctx.currentPage - 1);
  const sortDir = ctx.sortDesc ? -1 : 1 || -1;
  const sortBy = ctx.sortBy || "createdAt";

  return makeRequest(RECORD_SEARCH, {
    limit,
    offset,
    sortDir,
    sortBy,
    query: ctx.filter,
  });
};
