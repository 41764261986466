import store from "@/store/index";
import makeRequest from "@/requests/makeRequest";
import {
  ADD_PRINTSET_RECORDS,
  DELETE_PRINTSET_DATA,
  DELETE_PRINTSET,
  UPDATE_PRINTSET,
  REMOVE_PRINTSET_RECORD,
  ADD_PRINTSET,
} from "@/queries";

export const addRecordsToPrintSet = (printsetId, records, titleStrip) => {
  const data = records.map((id) => ({
    owner_id: { link: store.getters.getUser.id },
    print_id: { link: printsetId },
    record_id: { link: id },
    titleStrip
  }));
  const request = {
    data,
  };

  return makeRequest(ADD_PRINTSET_RECORDS, request)
};

export const removeRecordFromPrintSet = (printDataId) => makeRequest(REMOVE_PRINTSET_RECORD, {
  printDataId
})


export const createPrintSet = (form) => {
  const { title, page, printCount } = form;
  return makeRequest(ADD_PRINTSET, {
    title,
    page,
    printCount,
    userId: store.getters.getUser.id,
    currentDate: new Date().toISOString(),
  }).then((data) => {
    store.dispatch("getPrints");
    return data
  });
}
export const updatePrintSet = (form) => {
  const { id, title, status, page, printCount } = form;
  return makeRequest(UPDATE_PRINTSET, {
    id,
    title,
    status,
    page,
    printCount,
    currentDate: new Date().toISOString(),
  }).then(() => {
    store.dispatch("getPrints");
  });

}
export const deletePrintSet = (id) =>
  makeRequest(DELETE_PRINTSET_DATA, {
    id,
  }).then(() => {
    makeRequest(DELETE_PRINTSET, {
      id,
    }).then(() => {
      store.dispatch("getPrints");
    });
  });